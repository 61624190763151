import { CartTypes } from "../action_types/cart_types";

const initialState = {
  data: {
    cartproduct:{},
    // totalAmount:0,
    // totalQuantity:0
  }
};

export default function CartReducer(state = initialState, action = null) {
  if (!state) {
    state = initialState;
  }
  switch (action.type) {
    case CartTypes.GET_CART_ITEMS_SUCCESS: {
      return {
        ...state,
        data: action.data
      };
    }
    case CartTypes.ADD_TO_CART_SUCCESS: {
      return {
        ...state,
        data: action.data
      }
    }
    case CartTypes.REMOVE_CART_ITEMS_SUCCESS: {
      return {
        ...state,
        data: action.data
      }
    }
    case CartTypes.PROCEED_TO_WHATS_APP_SUCCESS: {
      return {
        ...state,
       
      }
    }
    case CartTypes.GET_MERCHANT_DETAILS_SUCCESS: {
      return {
        ...state,
        ...action.data
       
      }
    }
    case CartTypes.CLEAR_CART_SUCCESS: {
      return {
        ...state,
        data: action.data
       
      }
    }
    default:
      return state;
  }
}
