import { put, takeEvery } from "redux-saga/effects";
import { URI } from "../api/uri";
import { LoaderTypes } from "../action_types/loader_types";

import { OrderTypes } from "../action_types/order_types";

import requests from "../api";

// get Users list 
function* getOrdersByCustomer({ payload }) {
    // if(!params.searchText) {
    //     yield put({ type: LoaderTypes.LOADER_START });
    // }
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.GET_ALL_ORDERS}`;
        const res = yield  requests.post(url, payload);
        yield put({ type: OrderTypes.GET_TOTAL_ORDERS_SUCCESS, data: res });
    } catch (e) {
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}


export default function* OrderSaga() {
    yield takeEvery(OrderTypes.GET_TOTAL_ORDERS, getOrdersByCustomer);
}
