import { put, takeEvery, delay } from "redux-saga/effects";
import { URI } from "../api/uri";
import { LoaderTypes } from "../action_types/loader_types";
import { ProductTypes } from "../action_types/product_types";
import requests from "../api";

// const delay = (ms) => new Promise(res => setTimeout(res, ms))

// function* delay(){
//     yield put({ type: LoaderTypes.LOADER_STOP });
// }

//get Products list by category id
function* getProductByCategory({ payload, callback }){
    if(!payload.category_id) {
        yield put({ type: LoaderTypes.LOADER_START });
    }
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const obj = {
            ...payload,
            store_id: parseInt(JSON.parse(localStorage.getItem("adminInfo"))?.store_id),
        }
        const url = `${URI.GET_PRODUCT_BY_CATEGORY_LIST}`;
        const res = yield requests.post(url, obj);
        // callback(res);
        yield put({ type: ProductTypes.GET_PRODUCTS_BY_CATEGORY_SUCCESS, data: res.data });
    } catch (e) {
        console.log(e)
    }
    yield delay(3000, yield put({ type: LoaderTypes.LOADER_STOP }));
    // yield delay(3000)
    // setTimeout(() => {
    //     delay();
    //     console.log("delay: ex")
    // }, 3000)
}

function* getProductsBySearch({ payload, callback }){
    if(!payload.search_key) {
        yield put({ type: LoaderTypes.LOADER_START });
    }
    yield put({ type: LoaderTypes.LOADER_START });
    try {
   
        const url = `${URI.SEARCH_PRODUCT}`;
        const res = yield requests.post(url, payload);
        // callback(res);
        yield put({ type: ProductTypes.SEARCH_PRODUCT_SUCCESS, data: res.data });
    } catch (e) {
        console.log(e)
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}

function* setProductFromLocal({data}) {
    yield put({ type: ProductTypes.GET_PRODUCTS_BY_CATEGORY_SUCCESS, data: data });
}

function* updateAllProducts({ payload, callback }){
    if(!payload.search_key) {
        yield put({ type: LoaderTypes.LOADER_START });
    }
    yield put({ type: LoaderTypes.LOADER_START });
    try {
   
        const url = `${URI.UPDATE_ALL_PRODUCTS}`;
        const res = yield requests.post(url, payload);
        callback(res);
        // yield put({ type: ProductTypes.SEARCH_PRODUCT_SUCCESS, data: res.data.data });
    } catch (e) {
        console.log(e)
    }
    // yield put({ type: LoaderTypes.LOADER_STOP });
}

export default function* ProductSaga() {
    yield takeEvery(ProductTypes.GET_PRODUCTS_BY_CATEGORY, getProductByCategory);
    yield takeEvery(ProductTypes.SEARCH_PRODUCT, getProductsBySearch);
    yield takeEvery(ProductTypes.SET_PRODUCT_DATA, setProductFromLocal);
    yield takeEvery(ProductTypes.UPDATE_ALL_PRODUCTS, updateAllProducts);

}
