import { all } from "redux-saga/effects";

import LoggerSaga from "./logger_saga";
import CategorySaga from "./category_saga";
import CartSaga from "./cart_saga";
import ProductSaga from "./product_saga";
import MerchantSaga from "./merchant_saga";
import CustomerSaga from "./customer_saga";
import OrderSaga from "./order_saga";
import CouponSaga from "./coupon_saga";
import OptionSaga from "./option_saga";
import TemplateSaga from "./template_saga";
import StoreSaga from "./store_saga";
import CampaignSaga from "./campaign_saga";
import ReviewSaga from "./review_saga";
import PromotionSaga from "./promotion_saga";
import PaymentStatusSaga from "./paymentstatus_saga";
import BannerSaga from "./banner_saga";
export default function* RootSaga() {
  yield all([LoggerSaga(), CategorySaga(), ProductSaga(), CustomerSaga(), CartSaga(), MerchantSaga(),OrderSaga(),CouponSaga(),OptionSaga(),TemplateSaga(),StoreSaga(),CampaignSaga(),ReviewSaga(),PromotionSaga(),PaymentStatusSaga(),BannerSaga()]);
}
