// import { CouponTypes } from "../action_types/category_types";
import { TemplateTypes } from "../action_types/template_types";

const initialState = {};

export default function TemplateReducer(state = initialState, action = null) {
  if (!state) {
    state = initialState;
  }
  switch (action.type) {
    case TemplateTypes.GET_TEMPLATES_SUCCESS: {
      return {
        ...state,
        ...action.data
      };
    }
    default:
      return state;
  }
}
