const ProductTypes = {
    GET_PRODUCTS_BY_CATEGORY: "GET_PRODUCTS_BY_CATEGORY",
    GET_PRODUCTS_BY_CATEGORY_SUCCESS: "GET_PRODUCTS_BY_CATEGORY_SUCCESS",
    
    SEARCH_PRODUCT: "SEARCH_PRODUCT",
    SEARCH_PRODUCT_SUCCESS: "SEARCH_PRODUCT_SUCCESS",

    SET_PRODUCT_DATA: "SET_PRODUCT_DATA",
    
    UPDATE_ALL_PRODUCTS: "UPDATE_ALL_PRODUCTS",
    UPDATE_ALL_PRODUCTS_SUCCESS: "UPDATE_ALL_PRODUCTS_SUCCESS"
};

export { ProductTypes };
