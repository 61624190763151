import { put, takeEvery } from "redux-saga/effects";
import { URI } from "../api/uri";
import { LoaderTypes } from "../action_types/loader_types";
import { PromotionTypes } from "../action_types/promotion_types";
import API from "../api";


function* addPromotion({ payload }) {
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.ADD_PROMOTION}`;
        const res = yield API.post(url, payload);
        yield put({ type: PromotionTypes.ADD_PROMOTION, data: res.data });
    } catch (error) {
        console.log("Errors For", PromotionTypes.ADD_PROMOTION, error);
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}


function* editPromotion({ payload }){
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.EDIT_PROMOTION}`;
        const res = yield API.post(url, payload);
        yield put({ type: PromotionTypes.EDIT_PROMOTION, data: res.data });
    } catch (error) {
        console.log("Errors For", PromotionTypes.EDIT_PROMOTION, error);
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}


export default function* PromotionSaga() {
    yield takeEvery(PromotionTypes.ADD_PROMOTION, addPromotion);
    yield takeEvery(PromotionTypes.EDIT_PROMOTION, editPromotion);  
}
