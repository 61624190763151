import { put, takeEvery } from "redux-saga/effects";
import { URI } from "../api/uri";
import { LoaderTypes } from "../action_types/loader_types";
import { OptionTypes } from "../action_types/options_types";
import requests from "../api";

// get Users list 
function* getOptionsList({ payload }) {
    // if(!params.searchText) {
    //     yield put({ type: LoaderTypes.LOADER_START });
    // }
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.OPTIONS_LIST}`;
        const res = yield  requests.post(url, payload);
        yield put({ type: OptionTypes.GET_OPTIONS_SUCCESS, data: res.data });
    } catch (e) {
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}

function* addOptionsList({ payload }) {
    // if(!params.searchText) {
    //     yield put({ type: LoaderTypes.LOADER_START });
    // }
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.ADD_OPTIONS}`;
        const res = yield  requests.post(url, payload);
        yield put({ type: OptionTypes.ADD_OPTIONS_SUCCESS, data: res.data });
    } catch (e) {
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}


export default function* OptionSaga() {
    yield takeEvery(OptionTypes.GET_OPTIONS, getOptionsList);
    yield takeEvery(OptionTypes.ADD_OPTIONS, addOptionsList);
}
