import { OrderTypes } from "../action_types/order_types";

const initialState = {};

export default function OrderReducer(state = initialState, action = null) {
    if (!state) {
        state = initialState;
    }
  switch (action.type) {
    case OrderTypes.GET_TOTAL_ORDERS_SUCCESS: {
      return {
        ...state,
        ...action.data
      };
    }
    case OrderTypes.GET_TOTAL_ORDERS: {
      return {
        ...state,
        ...action.data
      };
    }
    default:
      return state;
  }
}
