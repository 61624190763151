// Relative Api Paths


const URI = {
  LOGIN_WITH_PASSWORD: `/loginWithPassword`,
  GET_SUPPORTED_LANGUAGES: `/getSupportedLanguages`,
  CATEGORY_LIST: `/getCategories`,
  BANNER_LIST: `../merchant/getBanners`,

  GET_PRODUCT_BY_CATEGORY_LIST: `../catalog/getProductsByCategory`,
  SEARCH_PRODUCT: `/searchProduct`,
  UPDATE_ALL_PRODUCTS: `/updateAllProducts`,


  ADD_TO_CART: `/addToCart`,
  GET_CART_ITEMS: `/getCartItems`,
  REMOVE_CART_ITEMS: `/removeFromCart`,
  PROCEED_TO_WHATS_APP: `/sendToWhatsApp`,
  GET_MERCHANT_DETAILS: `/getMerchantInfo`,
  CLEAR_CART: `/removeAllFromCart`,

  GET_CUSTOMERS_LIST: '/getCustomersList',
  GET_LEADS_LIST: '/getLeadsList',

  GET_LEAD_BY_ID: '/getLeadById',
  GET_CUSTOMERS_GROUPS: '/getCustomerGroups',
  GET_CUSTOMERS_IN_GROUPS: '/getCustomersInCustomerGroup',
  GET_LEAD_LOGS: '/getLeadLogs',
  GET_ORDERS_COUNT: '/getOrderStats',
  GET_ALL_ORDERS: '/getOrdersByCustomer',

  COUPONS_LIST: '/getCoupons',

  OPTIONS_LIST: '/getOptions',
  ADD_OPTIONS: '/addOption',


  GET_TEMPLATES_LIST: '/getTemplates',

  GET_STORE_TIMINGS: '/store-timings',

  GET_CAMPAIGNS_LIST: '/getCampaigns',

  GET_ORDER_REVIEW_RATINGS: '/getOrderRatings',

  ADD_PROMOTION: '/addPromotion',

  EDIT_PROMOTION: '/editPromotion',

  GET_ORDERS_BY_MOBILE: "/getOrdersByMobile",

  CHECK_PAYMENT_BY_ORDER_ID: "/checkPaymentByOrderId"
};

export { URI };
