import { put, takeEvery } from "redux-saga/effects";
import { URI } from "../api/uri";
import { LoaderTypes } from "../action_types/loader_types";

import { CustomerTypes } from "../action_types/customer_types";
import { LeadTypes } from "../action_types/lead_types";


import requests from "../api";

// get Users list 
function* getCustomersList({ payload }) {
    // if(!params.searchText) {
    //     yield put({ type: LoaderTypes.LOADER_START });
    // }
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.GET_CUSTOMERS_LIST}`;
        const res = yield  requests.post(url, payload);
        yield put({ type: CustomerTypes.GET_CUSTOMERS_SUCCESS, data: res.data });
    } catch (e) {
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}

function* getLeadsList({ payload }) {

    console.log("DATAADDDDD%%%%%%%%%%%%%%%%%%%%%%%%%%%%",payload);
    // if(!params.searchText) {
    //     yield put({ type: LoaderTypes.LOADER_START });
    // }
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.GET_LEADS_LIST}`;
        // payload.limit = 10000; 
      
        const res = yield  requests.post(url, payload);

      
        yield put({ type: LeadTypes.GET_LEADS_SUCCESS, data: res });
    } catch (e) {
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}


function* getLeadById({ payload }) {

    console.log("DATAADDDDD%%%%%%%%%%%%%%%%%%%%%%%%%%%%",payload);
    // if(!params.searchText) {
    //     yield put({ type: LoaderTypes.LOADER_START });
    // }
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.GET_LEAD_BY_ID}`;
      
        const res = yield  requests.post(url, payload);

      
        yield put({ type: LeadTypes.GET_LEAD_BY_ID_SUCCESS, data: res });
    } catch (e) {
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}

function* getLeadByLogs({ payload }) {

    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.GET_LEAD_LOGS}`;
      
        const res = yield  requests.post(url, payload);

      
        yield put({ type: LeadTypes.GET_LEAD_LOGS_SUCCESS, data: res });
    } catch (e) {
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}

function* getAllCustomersList({ payload }) {
    // if(!params.searchText) {
    //     yield put({ type: LoaderTypes.LOADER_START });
    // }
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.GET_CUSTOMERS_LIST}`;
        payload.limit = '10000';
        const res = yield  requests.post(url, payload);
        yield put({ type: CustomerTypes.GET_ALL_CUSTOMERS_SUCCESS, data: res.data });
    } catch (e) {
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}




// get customers group list
function* getCustomerGroups({ payload }) {
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.GET_CUSTOMERS_GROUPS}`;
        const res = yield  requests.post(url, payload);
        yield put({ type: CustomerTypes.GET_CUSTOMER_GROUPS_SUCCESS, data: res.data });
    } catch (e) {
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}

function* getCustomersInGroups({ payload }) {
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.GET_CUSTOMERS_IN_GROUPS}`;
        const res = yield  requests.post(url, payload);
    
        yield put({ type: CustomerTypes.GET_CUSTOMERS_IN_GROUP_SUCCESS, data: res.data });
    } catch (e) {
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}





export default function* CustomerSaga() {
    yield takeEvery(LeadTypes.GET_LEADS, getLeadsList);

    yield takeEvery(LeadTypes.GET_LEAD_BY_ID, getLeadById);
    yield takeEvery(LeadTypes.GET_LEAD_LOGS, getLeadByLogs);

    yield takeEvery(CustomerTypes.GET_CUSTOMERS, getCustomersList);
    yield takeEvery(CustomerTypes.GET_CUSTOMER_GROUPS, getCustomerGroups);
    yield takeEvery(CustomerTypes.GET_ALL_CUSTOMERS, getAllCustomersList);
    yield takeEvery(CustomerTypes.GET_CUSTOMERS_IN_GROUP, getCustomersInGroups);
}
