import { put, takeEvery } from "redux-saga/effects";
import { URI } from "../api/uri";
import { LoaderTypes } from "../action_types/loader_types";
import { CategoryTypes } from "../action_types/category_types";

import requests from "../api";

// get Users list 
function* getCategoryList({ payload }) {
 
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.CATEGORY_LIST}`;
        const res = yield  requests.post(url, payload);
        yield put({ type: CategoryTypes.GET_CATEGORIES_SUCCESS, data: res.data });
    } catch (e) {
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}


export default function* CategorySaga() {
    yield takeEvery(CategoryTypes.GET_CATEGORIES, getCategoryList);
}
