import { put, takeEvery } from "redux-saga/effects";
import { URI } from "../api/uri";
import { LoaderTypes } from "../action_types/loader_types";
import { StoreTypes } from "../action_types/store_types";
import requests from "../api";

// get Users list 
function* getStoreTimings({ payload }) {
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.GET_STORE_TIMINGS}`;
        const res = yield requests.get(url, payload);
        yield put({ type: StoreTypes.GET_STORE_TIMINGS_SUCCESS, data: res.data });
    } catch (e) {
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}


export default function* StoreSaga() {
    yield takeEvery(StoreTypes.GET_STORE_TIMINGS, getStoreTimings);
}
