const CustomerTypes = {
    GET_CUSTOMERS: "GET_CUSTOMERS",
    GET_CUSTOMERS_SUCCESS: "GET_CUSTOMERS_SUCCESS",
    GET_CUSTOMER_GROUPS: "GET_CUSTOMER_GROUPS",
    GET_CUSTOMER_GROUPS_SUCCESS: "GET_CUSTOMER_GROUPS_SUCCESS",

    GET_ALL_CUSTOMERS: "GET_ALL_CUSTOMERS",
    GET_ALL_CUSTOMERS_SUCCESS: "GET_ALL_CUSTOMERS_SUCCESS",

    GET_CUSTOMERS_IN_GROUP: "GET_CUSTOMERS_IN_GROUP",
    GET_CUSTOMERS_IN_GROUP_SUCCESS: "GET_CUSTOMERS_IN_GROUP_SUCCESS"


};

export { CustomerTypes };