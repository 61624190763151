import { put, takeEvery } from "redux-saga/effects";
import { URI } from "../api/uri";
import { LoaderTypes } from "../action_types/loader_types";
import { ReviewTypes } from "../action_types/review_types";
import requests from "../api";

function* getOrderRatings({ payload }) {
    console.log(payload)
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.GET_ORDER_REVIEW_RATINGS}`;
        const res = yield  requests.post(url, payload);
        console.log(res,"Response")
        yield put({ type: ReviewTypes.GET_ORDER_RATINGS_SUCCESS, data: res });
    } catch (e) {
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}


export default function* ReviewSaga() {
    yield takeEvery(ReviewTypes.GET_ORDER_RATINGS, getOrderRatings);
}
