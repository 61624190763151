import { put, takeEvery } from "redux-saga/effects";
import { URI } from "../api/uri";
import { LoaderTypes } from "../action_types/loader_types";
import { CartTypes } from "../action_types/cart_types";
import API from "../api";


// add to Cart Item 
function* addToCart({ payload }) {
    // if(!params.searchText) {
    //     yield put({ type: LoaderTypes.LOADER_START });
    // }
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.ADD_TO_CART}`;
        const res = yield API.post(url, payload);
        yield put({ type: CartTypes.ADD_TO_CART_SUCCESS, data: res.data.data });
    } catch (e) {
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}

//get cart list
function* getCartItems({ payload }){
    if(!payload.customer_id) {
        yield put({ type: LoaderTypes.LOADER_START });
    }
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.GET_CART_ITEMS}`;
        const res = yield API.post(url, payload);
        // callback(res);
        yield put({ type: CartTypes.GET_CART_ITEMS_SUCCESS, data: res.data.data });
    } catch (e) {
        console.log(e)
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}

// remove cart item by key
function* removeCartItem({ payload }) {
    // if(!params.searchText) {
    //     yield put({ type: LoaderTypes.LOADER_START });
    // }
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.REMOVE_CART_ITEMS}`;
        const res = yield API.post(url, payload);
        yield put({ type: CartTypes.REMOVE_CART_ITEMS_SUCCESS, data: res.data.data });
    } catch (e) {
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}

// remove cart item by key
function* sendCartToWhatsApp({ payload }) {
    // if(!params.searchText) {
    //     yield put({ type: LoaderTypes.LOADER_START });
    // }
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.PROCEED_TO_WHATS_APP}`;
        const res = yield API.post(url, payload);
        yield put({ type: CartTypes.PROCEED_TO_WHATS_APP_SUCCESS, data: res.data.data });
    } catch (e) {
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}

// clear Cart Items
function* clearCart({ payload }) {
    // if(!params.searchText) {
    //     yield put({ type: LoaderTypes.LOADER_START });
    // }
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.CLEAR_CART}`;
        const res = yield API.post(url, payload);
        yield put({ type: CartTypes.CLEAR_CART_SUCCESS, data: res.data.data });
    } catch (e) {
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}

export default function* CartSaga() {
    yield takeEvery(CartTypes.ADD_TO_CART, addToCart);
    yield takeEvery(CartTypes.GET_CART_ITEMS, getCartItems);
    yield takeEvery(CartTypes.REMOVE_CART_ITEMS, removeCartItem);
    yield takeEvery(CartTypes.PROCEED_TO_WHATS_APP, sendCartToWhatsApp);
    yield takeEvery(CartTypes.CLEAR_CART, clearCart);    
}
