import { CategoryTypes } from "../action_types/category_types";

const initialState = {
    category_info: []
};

export default function CategoryReducer(state = initialState, action = null) {
  if (!state) {
    state = initialState;
  }
  switch (action.type) {
    case CategoryTypes.GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        ...action.data
      };
    }
    default:
      return state;
  }
}
