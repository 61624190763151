import { put, takeEvery } from "redux-saga/effects";
import { URI } from "../api/uri";
import { LoaderTypes } from "../action_types/loader_types";
import { BannerTypes } from "../action_types/banner_types";
import requests from "../api";

function* getBannerList({ payload }) {
 
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.BANNER_LIST}`;
        const res = yield  requests.post(url, payload);
        yield put({ type: BannerTypes.GET_BANNERS_SUCCESS, data: res.data });
    } catch (e) {
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}

export default function* BannerSaga() {
    yield takeEvery(BannerTypes.GET_BANNERS, getBannerList);
}