import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import TokenServices from '../src/services/Firebase/TokenServices';
// var firebaseConfig = {
//   apiKey: "AIzaSyAjWIJe9AaL6fDZVn9tRajF-BUexEPFZyA",
//   authDomain: "react-notif-40228.firebaseapp.com",
//   projectId: "react-notif-40228",
//   storageBucket: "react-notif-40228.appspot.com",
//   messagingSenderId: "790644971731",
//   appId: "1:790644971731:web:dc0c5d007d2b961af3dc26"
// };

const firebaseConfig = {
  apiKey: "AIzaSyC-JfckjdPRe0dHTNSco6hVRPE5CV_QRDs",
  authDomain: "wroti-77968.firebaseapp.com",
  projectId: "wroti-77968",
  storageBucket: "wroti-77968.appspot.com",
  messagingSenderId: "678448474303",
  appId: "1:678448474303:web:ee47f2b2bb5f98afba3e0a",
  measurementId: "G-MLKVMQSRST"
};
const firebaseApp =  initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound) => {
  return getToken(messaging, { vapidKey: 'BNIg4o26_OigaXCC4yYhkF4raHavHYpdlzTuryWbJRd0zy2b4T66VwUPurjhVaeqP25Z9-It7OY7Co-6KLoe5J8' })
    .then((currentToken) => {
      if (currentToken) {
        console.log('Current token for client: ', currentToken);
        setTokenFound(true);
        localStorage.setItem('fcmToken', currentToken);
        let merchantMobileNumber = JSON.parse(localStorage.getItem("adminInfo"))?.mobileNumber;
        let currentDeviceId = JSON.parse(localStorage.getItem("adminInfo"))?.deviceId;
        // Save the token to your backend
        if(merchantMobileNumber){
          TokenServices.saveToken({
            fcmToken: currentToken,
            deviceId:currentDeviceId ,
            mobileNumber: merchantMobileNumber
          })
          .then(() => {
            console.log("Token saved successfully.");
          })
          .catch((err) => {
            console.error("Error saving token to the backend: ", err);
          });
        }

        // Additional actions or UI updates can be added here.

      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
        // Update the UI to indicate that permission may be required.
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token: ', err);
      // Handle any errors that occur during token retrieval.
    });
};


export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});