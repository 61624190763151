import { OptionTypes } from "../action_types/options_types";
const initialState = {
    productoptions: []
};

export default function OptionReducer(state = initialState, action = null) {
  if (!state) {
    state = initialState;
  }
  switch (action.type) {
    case OptionTypes.GET_OPTIONS_SUCCESS: {
      return {
        ...state,
        ...action.data
      };
    }
    case OptionTypes.ADD_OPTIONS_SUCCESS: {
        return {
          ...state,
          ...action.data
        };
      }
    default:
      return state;
  }
}
