import { CustomerTypes } from "../action_types/customer_types";
import { LeadTypes } from "../action_types/lead_types";
const initialState = {
    customers: [],
    leads: [],
    lead:{},
    customergroups: []
};

export default function CustomerReducer(state = initialState, action = null) {
  if (!state) {
    state = initialState;
  }
  switch (action.type) {


    case CustomerTypes.GET_CUSTOMERS_SUCCESS: {
      if(action.data.success){
        return {
          ...state,
          ...action.data
        };
      }else{
        return{
          ...state,
          customers: []
        }
      }
      
    }
    case LeadTypes.GET_LEAD_BY_ID_SUCCESS: {
    
      if(action.data.success){
        
        return {
          ...state,
          ...action.data
        };
      }else{
        return{
          ...state,
          lead: {}
        }
      }
      
    }

    case LeadTypes.GET_LEADS_SUCCESS: {
    
      if(action.data.success){
        
        return {
          ...state,
          ...action.data
        };
      }else{
        return{
          ...state,
          leads: []
        }
      }
      
    }
    case LeadTypes.GET_LEAD_LOGS_SUCCESS: {
    
      if(action.data.success){
        
        return {
          ...state,
          ...action.data
        };
      }else{
        return{
          ...state,
          leads: []
        }
      }
      
    }

    case CustomerTypes.GET_CUSTOMER_GROUPS_SUCCESS: {
      if(action.data.success){
        return {
          ...state,
          ...action.data
        };
      }else{
        return{
          ...state,
          customergroups: []
        }
      }
    }
    default:
      return state;
  }
}
