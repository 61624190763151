export function openDatabase() {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('my-database', 1);
        request.onupgradeneeded = event => {
            const db = event.target.result;
            db.createObjectStore('my-store', { keyPath: 'id' });
        };
        request.onsuccess = event => resolve(event.target.result);
        request.onerror = event => reject(event.target.error);
    });
}

export function addData(db, data) {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(['my-store'], 'readwrite');
        const store = transaction.objectStore('my-store');
        const request = store.add(data);
        request.onsuccess = () => resolve();
        request.onerror = event => reject(event.target.error);
    });
}

export function getData(db, id) {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(['my-store'], 'readonly');
        const store = transaction.objectStore('my-store');
        const request = store.get(id);
        request.onsuccess = () => resolve(request.result);
        request.onerror = event => reject(event.target.error);
    });
}

export function updateData(db, data) {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(['my-store'], 'readwrite');
        const store = transaction.objectStore('my-store');
        const request = store.put(data);
        request.onsuccess = () => resolve();
        request.onerror = event => reject(event.target.error);
    });
}

export function deleteData(db, id) {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(['my-store'], 'readwrite');
        const store = transaction.objectStore('my-store');
        const request = store.delete(id);
        request.onsuccess = () => resolve();
        request.onerror = event => reject(event.target.error);
    });
}
