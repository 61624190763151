import { put, takeEvery } from "redux-saga/effects";
import { URI } from "../api/uri";
import { LoaderTypes } from "../action_types/loader_types";
import requests from "../api";
import { CampaignTypes } from "../action_types/campaign_types";

// get Users list 
function* getCampaignList({payload}) {
    // if(!params.searchText) {
    //     yield put({ type: LoaderTypes.LOADER_START });
    // }
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.GET_CAMPAIGNS_LIST}`;
        const res = yield requests.post(url,payload);
        console.log("payoload",res)
        yield put({ type: CampaignTypes.GET_CAMPAIGNS_SUCCESS, data: res });
    } catch (e) {
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}


export default function* CampaignSaga() {
    yield takeEvery(CampaignTypes.GET_CAMPAIGNS, getCampaignList);
}
