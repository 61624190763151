const LeadTypes = {
    GET_LEADS: "GET_LEADS",
    GET_LEADS_SUCCESS: "GET_LEADS_SUCCESS",
  

    GET_ALL_LEADS: "GET_ALL_LEADS",
   
    GET_ALL_LEADS_SUCCESS: "GET_ALL_LEADS_SUCCESS",

    GET_LEAD_BY_ID: "GET_LEAD_BY_ID",
   
    GET_LEAD_BY_ID_SUCCESS: "GET_LEAD_BY_ID_SUCCESS",

   GET_LEAD_LOGS : "GET_LEAD_LOGS",

   GET_LEAD_LOGS_SUCCESS : "GET_LEAD_LOGS_SUCCESS"


};

export { LeadTypes };