import { CartTypes } from "../action_types/cart_types";
import { AuthTypes } from "../action_types/auth_types";
const initialState = { 
};

export default function MerchantReducer(state = initialState, action = null) {
  if (!state) {
    state = initialState;
  }
  switch (action.type) {    
    case CartTypes.GET_MERCHANT_DETAILS_SUCCESS: {
      return {
        ...state,
        ...action.data
       
      }
    }
    case AuthTypes.LOGIN_WITH_PASSWORD_SUCCESS: {
      return {
        ...state,
        ...action.data
       
      }
    }
    case AuthTypes.GET_ORDERS_COUNT_SUCCESS: {
      return {
        ...state,
        ...action.data
       
      }
    }
    default:
      return state;
  }
}
