import { CustomerTypes } from "../action_types/customer_types";

const initialState = {
    customers: []
};

export default function CustomerInGroupReducer(state = initialState, action = null) {
  if (!state) {
    state = initialState;
  }
  switch (action.type) {
    case CustomerTypes.GET_CUSTOMERS_IN_GROUP_SUCCESS: {
      if(action.data.success){
        return {
          ...state,
          ...action.data
        };
      }else{
        return{
          ...state,
          customers: []
        }
      }
      
    }
    default:
      return state;
  }
}
