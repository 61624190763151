import { ReviewTypes } from "../action_types/review_types";

const initialState = {};

export default function ReviewReducer(state = initialState, action = null) {
    if (!state) {
        state = initialState;
    }
    console.log(state)
  switch (action.type) {
    case ReviewTypes.GET_ORDER_RATINGS_SUCCESS: {
      return {
        ...state,
        ...action.data
      };
    }
    case ReviewTypes.GET_ORDER_RATINGS: {
      return {
        ...state,
        ...action.data
      };
    }
    default:
      return state;
  }
}
