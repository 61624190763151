import { put, takeEvery } from "redux-saga/effects";
import { URI } from "../api/uri";
import { LoaderTypes } from "../action_types/loader_types";
import { PaymentStatusTypes } from "../action_types/paymentstatus_types";
import requests from "../api";

function* getOrdersByMobile({ payload }) {
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.GET_ORDERS_BY_MOBILE}`;
        const res = yield  requests.post(url, payload);
        yield put({ type: PaymentStatusTypes.GET_ORDERS_BY_MOBILE, data: res.data });
    } catch (e) {
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}

function* checkPaymentByOrderId({ payload }) {
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.CHECK_PAYMENT_BY_ORDER_ID}`;
        const res = yield  requests.post(url, payload);
        yield put({ type: PaymentStatusTypes.CHECK_PAYMENT_BY_ORDER_ID, data: res.data });
    } catch (e) {
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}

export default function* PaymentStatusSaga() {
    yield takeEvery(PaymentStatusTypes.GET_ORDERS_BY_MOBILE, getOrdersByMobile);
    yield takeEvery(PaymentStatusTypes.CHECK_PAYMENT_BY_ORDER_ID, checkPaymentByOrderId);
}
