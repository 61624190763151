import { StoreTypes } from "../action_types/store_types";

const initialState = {
    store_timings:{}
};

export default function StoreReducer(state = initialState, action = null) {
  if (!state) {
    state = initialState;
  }
  switch (action.type) {
    case StoreTypes.GET_STORE_TIMINGS_SUCCESS: {
      return {
        ...state,
        ...action.data
      };
    }
    default:
      return state;
  }
}
