import React, { lazy,useContext,useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
} from "react-router-dom";
import { useState } from "react";
import { ToastContainer, notifyError, notifySuccess } from "./utils/toast";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import PrivateRoute from "./components/login/PrivateRoute";
import { fetchToken, onMessageListener } from "./firebase";
import { AdminContext } from '../src/context/AdminContext';
import { Button } from "@windmill/react-ui";
import CustomAlert from "./components/alerts";
import { openDatabase, getData, deleteData } from "./indexedDB";

let sound = new Audio("https://uatimages.wroti.app/notifications.mp3");

const Layout = lazy(() => import("./layout/Layout"));
const Login = lazy(() => import("./pages/Login"));
const SignUp = lazy(() => import("./pages/SignUp"));
const ForgetPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));

const App = () => {
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isTokenFound, setTokenFound] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const {state, dispatch } = useContext(AdminContext);
  const role = JSON.parse(localStorage.getItem("adminInfo"))?.role;
  // const adminInfo = JSON.parse(localStorage.getItem('adminInfo'));
  const { adminInfo } = state;
  let continuousPlay = adminInfo?.continuousPlay;
  setInterval(async () => {
    const db = await openDatabase();
    const data = await getData(db, "notificationBuzzer");
    if (data?.data == "true") {
      setShowAlert(true);
      await deleteData(db, "notificationBuzzer");
      if(continuousPlay){
        console.log(continuousPlay,"PLAYING SOUND CONTINUOUSLY");
        playSoundContinuously();
      }else{
        console.log(continuousPlay,"PLAYING SOUND ONE TIME ONLY");
        playSound();
      }
    }
  }, 500);

  useEffect(() => {
    // Interval to click the hidden button every 5 seconds
    const intervalId = setInterval(() => {
      const hiddenButton = document.getElementById("hiddenButton");
      if (hiddenButton) {
        hiddenButton.click();
        playSilentAudio();
        console.log("Hidden button was clicked!");
      }
    }, 5000);

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, []);

  const playSoundContinuously = () => {
      sound.loop = true;
    sound.play().catch((error) => {
      console.error("Audio playback failed:", error);
    });
  };

  const playSound = () => {
  sound.play().catch((error) => {
    console.error("Audio playback failed:", error);
  });
};

  fetchToken(setTokenFound);
  onMessageListener()
    .then((payload) => {
      console.log("MESSAGE RECEIVED", "DDDDDD", payload);
      if(continuousPlay){
        console.log(continuousPlay,"PLAYING SOUND CONTINUOUSLY");
        playSoundContinuously();
      }else{
        console.log(continuousPlay,"PLAYING SOUND ONE TIME ONLY");
        playSound();
      }
      // setNotification({title: payload.notification.title, body: payload.notification.body})
      //  setShow(true);
      setShowAlert(true);
    })
    .catch((err) => console.log("failed: ", err));

  const stopSound = () => {
    console.log("Stop sound");
    if (sound) sound.pause();
    sound.loop = false; // Stop looping
    setShowAlert(false); // Hide the alert
  };

  const playSilentAudio = () => {
    const silentAudio = new Audio("data:audio/wav;base64,UklGRigAAABXQVZFZm10IBAAAAABAAEARKwAABCxAgAEABAAZGF0YQAAAAA=");
    silentAudio.play()
      .then(() => {
        console.log("Silent audio playback succeeded.");
      })
      .catch((error) => {
        console.error("Silent audio playback failed:", error);
      });
  };

  useEffect(() => {
    window.hasInteracted = true;
    window.addEventListener("mouseover", handleUserInteraction);
    window.addEventListener("mouseout", handleUserInteraction);
    // Trigger audio playback when the user clicks anywhere on the page
    const handleUserInteraction = () => {
      playSilentAudio();
      window.removeEventListener("click", handleUserInteraction); // Remove event listener after playback
    };

    window.addEventListener("click", handleUserInteraction);

    return () => {
      window.removeEventListener("click", handleUserInteraction);
    };
  }, []);
  return (
    <>
      <ToastContainer />
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/signup" component={SignUp} />
          <Route path="/forgot-password" component={ForgetPassword} />
          <Route path="/reset-password/:token" component={ResetPassword} />

          <PrivateRoute>
            {" "}
            <Route path="/" component={Layout} />
          </PrivateRoute>
          <Redirect exact from="/" to="/login" />
        </Switch>
        {(showAlert && adminInfo) && (
          <>
            <CustomAlert
              onOkPress={stopSound}
              message="New order received"
              showAlert={showAlert}
              setShowAlert={setShowAlert}
            />
          </>
        )}
      </Router>
       {/* Hidden button that will be clicked automatically */}
       <button id="hiddenButton" style={{ display: "none" }}>
        Hidden Button
      </button>
    </>
  );
};

export default App;

//An error occurred while retrieving token.  FirebaseError: Messaging: We are unable to register the default service worker. Failed to register a ServiceWorker for scope ('http://localhost:4000/firebase-cloud-messaging-push-scope') with script ('http://localhost:4000/firebase-messaging-sw.js'): The script has an unsupported MIME type ('text/html').
