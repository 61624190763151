import { Button } from "@windmill/react-ui";
import React, { useState,lazy } from "react";
import { useHistory } from 'react-router-dom';
import { Link } from "react-router-dom/cjs/react-router-dom";

const CustomAlert = ({ message, showAlert, setShowAlert,onOkPress }) => {
    

  const history = useHistory();
  const handleOKClick = () => {
    setShowAlert(false);
    onOkPress();
    // Check if the current location is "orders" before refreshing
    if (history.location.pathname === '/orders') {
      // Refresh the page if the current location is "orders"
      window.location.reload();
    } else {
      // Navigate to the "orders" page if the current location is different
      history.push('/orders');
    }
  };
    return (
        <div className="fixed top-0 left-0 w-full flex items-center justify-center h-screen bg-opacity-75 bg-gray-400">
          <div className="bg-white text-black p-4 mx-auto rounded-lg shadow-lg" style={{position:"fixed",top:"100px"}}>
            <div className="flex items-center">
              <svg className="w-6 h-6 text-black-300" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 1a9 9 0 100 18 9 9 0 000-18zm0 16a7 7 0 110-14 7 7 0 010 14z"/>
                <path d="M10 4.5a1 1 0 00-1 1v5a1 1 0 002 0V5.5a1 1 0 00-1-1z"/>
                <path d="M10 12a1 1 0 00-1 1V7a1 1 0 002 0v6a1 1 0 00-1-1z"/>
              </svg>
              <p className="ml-2 text-lg font-semibold">{message}</p>
            </div>
            
            <Button
              className="mt-4 px-4 py-2 bg-#057a55-700 text-white rounded hover:bg-green-900 focus:outline-none focus:ring focus:ring-blue-400"
              style={{marginLeft:"4rem"}}
              onClick={handleOKClick}
            >
               
              OK
            </Button>
          </div>
        </div>
      );
    
};

export default CustomAlert;
