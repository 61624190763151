import { PaymentStatusTypes } from "../action_types/paymentstatus_types";

const initialState = {
};

export default function PaymentStatusReducer(state = initialState, action = null) {
    if (!state) {
        state = initialState;
    }
    switch (action.type) {
        case PaymentStatusTypes.GET_ORDERS_BY_MOBILE: {
            return {
                ...state,
                ...action.data

            }
        }
        case PaymentStatusTypes.CHECK_PAYMENT_BY_ORDER_ID: {
            return {
                ...state,
                ...action.data

            }
        }
        default:
            return state;
    }
}
