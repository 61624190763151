import { put, takeEvery } from "redux-saga/effects";
import { URI } from "../api/uri";
import { LoaderTypes } from "../action_types/loader_types";
import { AuthTypes } from "../action_types/auth_types";
import requests from "../api";


// get merchant details
function* getMerchantDetails({ payload }) {
    // if(!params.searchText) {
    //     yield put({ type: LoaderTypes.LOADER_START });
    // }
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.GET_MERCHANT_DETAILS}`;
        const res = yield requests.post(url, payload);
        yield put({ type: AuthTypes.GET_MERCHANT_DETAILS_SUCCESS, data: res.data });
    } catch (e) {
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}

function* loginWithPassword({ payload, callback }) {
    // if(!params.searchText) {
    //     yield put({ type: LoaderTypes.LOADER_START });
    // }
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.LOGIN_WITH_PASSWORD}`;
        const res = yield requests.post(url, payload);
        callback(res);
        yield put({ type: AuthTypes.LOGIN_WITH_PASSWORD_SUCCESS, data: res });
    } catch (e) {
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}

function* getSupportedLanguages({ payload }) {
    // if(!params.searchText) {
    //     yield put({ type: LoaderTypes.LOADER_START });
    // }
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.GET_SUPPORTED_LANGUAGES}`;
        const res = yield requests.post(url, payload);
        yield put({ type: AuthTypes.GET_SUPPORTED_LANGUAGES_SUCCESS, data: res });
    } catch (e) {
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}

function* getOrdersCount({ payload }) {
    // if(!params.searchText) {
    //     yield put({ type: LoaderTypes.LOADER_START });
    // }
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.GET_ORDERS_COUNT}`;
        const res = yield requests.post(url, payload);
        yield put({ type: AuthTypes.GET_ORDERS_COUNT_SUCCESS, data: res?.data });
    } catch (e) {
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}

export default function* MerchantSaga() {
    yield takeEvery(AuthTypes.GET_MERCHANT_DETAILS, getMerchantDetails);    
    yield takeEvery(AuthTypes.LOGIN_WITH_PASSWORD, loginWithPassword);    
    yield takeEvery(AuthTypes.GET_SUPPORTED_LANGUAGES, getSupportedLanguages);    
    yield takeEvery(AuthTypes.GET_ORDERS_COUNT, getOrdersCount);    
}
