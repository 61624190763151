const CartTypes = {
    ADD_TO_CART: "ADD_TO_CART",
    ADD_TO_CART_SUCCESS: "ADD_TO_CART_SUCCESS",
    GET_CART_ITEMS: "GET_CART_ITEMS",
    GET_CART_ITEMS_SUCCESS: "GET_CART_ITEMS_SUCCESS",
    REMOVE_CART_ITEMS: "REMOVE_CART_ITEMS",
    REMOVE_CART_ITEMS_SUCCESS: "REMOVE_CART_ITEMS_SUCCESS",
    PROCEED_TO_WHATS_APP  : "PROCEED_TO_WHATS_APP",
    PROCEED_TO_WHATS_APP_SUCCESS : "PROCEED_TO_WHATS_APP_SUCCESS",
    GET_MERCHANT_DETAILS: "GET_MERCHANT_DETAILS",
    GET_MERCHANT_DETAILS_SUCCESS: "GET_MERCHANT_DETAILS_SUCCESS",
    CLEAR_CART: "CLEAR_CART",
    CLEAR_CART_SUCCESS: "CLEAR_CART_SUCCESS" 
};

export { CartTypes };
