// import { CouponTypes } from "../action_types/category_types";
import { CampaignTypes } from "../action_types/campaign_types";

const initialState = {};

export default function CampaignReducer(state = initialState, action = null) {
  if (!state) {
    state = initialState;
  }
  switch (action.type) {
    case CampaignTypes.GET_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        ...action.data
      };
    }
    default:
      return state;
  }
}
