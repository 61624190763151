import { put, takeEvery } from "redux-saga/effects";
import { URI } from "../api/uri";
import { LoaderTypes } from "../action_types/loader_types";

import requests from "../api";
import { TemplateTypes } from "../action_types/template_types";

// get Users list 
function* getTemplateslist({payload}) {
    // if(!params.searchText) {
    //     yield put({ type: LoaderTypes.LOADER_START });
    // }
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.GET_TEMPLATES_LIST}`;
        const res = yield requests.post(url,payload);
        yield put({ type: TemplateTypes.GET_TEMPLATES_SUCCESS, data: res });
    } catch (e) {
        // console.log(e)
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}


export default function* TemplateSaga() {
    yield takeEvery(TemplateTypes.GET_TEMPLATES, getTemplateslist);
}
