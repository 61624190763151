import { BannerTypes } from "../action_types/banner_types";

const initialState = {
    banners:[]
};

export default function BannerReducer(state = initialState, action = null) {
  if (!state) {
    state = initialState;
  }
  switch (action.type) {
    case BannerTypes.GET_BANNERS_SUCCESS: {
      return {
        ...state,
        ...action.data
      };
    }
    default:
      return state;
  }
}
