import { ProductTypes } from "../action_types/product_types";

const initialState = {
    products: []
};

export default function ProductReducer(state = initialState, action = null) {
  if (!state) {
    state = initialState;
  }
  switch (action.type) {
    case ProductTypes.GET_PRODUCTS_BY_CATEGORY_SUCCESS: {
      return {
        ...state,
        ...action.data
      };
    }
    case ProductTypes.SEARCH_PRODUCT_SUCCESS: {
      return {
        ...state,
        ...action.data
      };
    }
    default:
      return state;
  }
}
