import { PromotionTypes } from "../action_types/promotion_types";

const initialState = {
  data: {
  }
};

export default function PromotionReducer(state = initialState, action = null) {
  if (!state) {
    state = initialState;
  }
  switch (action.type) {
    case PromotionTypes.ADD_PROMOTION: {
      return {
        ...state,
        data: action.data
      }
    }
    case PromotionTypes.EDIT_PROMOTION: {
      return {
        ...state,
        data: action.data
      }
    }
    default:
      return state;
  }
}
