const AuthTypes = {
    LOGIN_WITH_PASSWORD: "LOGIN_WITH_PASSWORD",
    LOGIN_WITH_PASSWORD_SUCCESS: "LOGIN_WITH_PASSWORD_SUCCESS",
    GET_MERCHANT_DETAILS: "GET_MERCHANT_DETAILS",  
    GET_MERCHANT_DETAILS_SUCCESS: "GET_MERCHANT_DETAILS_SUCCESS",  
    GET_SUPPORTED_LANGUAGES: "GET_SUPPORTED_LANGUAGES",  
    GET_SUPPORTED_LANGUAGES_SUCCESS: "GET_SUPPORTED_LANGUAGES_SUCCESS",
    GET_ORDERS_COUNT: "GET_ORDERS_COUNT",
    GET_ORDERS_COUNT_SUCCESS: "GET_ORDERS_COUNT_SUCCESS"  
  };
  
  export { AuthTypes };
  