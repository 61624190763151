import { put, takeEvery } from "redux-saga/effects";
import { URI } from "../api/uri";
import { LoaderTypes } from "../action_types/loader_types";

import { CouponTypes } from "../action_types/coupon_types";
import requests from "../api";

// get Users list 
function* getCouponslist({ payload }) {
    // if(!params.searchText) {
    //     yield put({ type: LoaderTypes.LOADER_START });
    // }
    yield put({ type: LoaderTypes.LOADER_START });
    try {
        const url = `${URI.COUPONS_LIST}`;
        const res = yield  requests.post(url, payload);
        yield put({ type: CouponTypes.GET_COUPONS_SUCCESS, data: res.data });
    } catch (e) {
    }
    yield put({ type: LoaderTypes.LOADER_STOP });
}


export default function* CouponSaga() {
    yield takeEvery(CouponTypes.GET_COUPONS, getCouponslist);
}
