import { combineReducers } from "redux";

import LoaderReducer from "./loader_reducer";
import CategoryReducer from "./category_reducer";
import ProductReducer from "./product_reducer";
import CartReducer from "./cart_reducer";
import MerchantReducer from "./merchant_reducer";
import CustomerReducer from "./customer_reducer";
import AllCustomerReducer from "./all_customer_reducer";
import CustomerInGroupReducer from "./customer_in_group_reducer";
import OrderReducer from "./order_reducer";
import CouponReducer from "./coupon_reducer";
import OptionReducer from "./option_reducer";
import TemplateReducer from "./template_reducer";
import StoreReducer from "./store_timings_reducer";
import CampaignReducer from "./campaign_reducer";
import ReviewReducer from "./review_reducer";
import PromotionReducer from "./promotion_reducer";
import PaymentStatusReducer from "./paymentstatus_reducer";
import BannerReducer from "./banner_reducer";
const RootReducer = combineReducers({
  Loader: LoaderReducer,
  Category: CategoryReducer,
  Customers: CustomerReducer,
  Product: ProductReducer,
  Cart: CartReducer,
  Merchant: MerchantReducer,
  AllCustomers: AllCustomerReducer,
  CustomersInGroup: CustomerInGroupReducer,
  Orders:OrderReducer,
  Coupons:CouponReducer,
  Options:OptionReducer,
  Templates:TemplateReducer,
  storetimings:StoreReducer,
  Campaigns:CampaignReducer,
  Reviews : ReviewReducer,
  Promotion: PromotionReducer,
  PaymentStatus: PaymentStatusReducer,
  Banner: BannerReducer,
});

export default RootReducer;
